<div *ngIf="errorMessage?.length > 0" class="WarningBanner alert alert-dismissible alert-warning fade show ml-3"
	role="alert">
	<button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="errorMessage = ''">
		<span aria-hidden="true">&times;</span>
	</button>
	<p>
		<strong>{{errorMessage}}</strong>
	</p>
</div>

<div class="row m-0 ml-3">
	<button type="submit" class="btn cstm-btn-primary mb-2 createBtn" (click)="
      this.buttonName = 'Create';
      saveCartForm.get('cartName').reset();
      onNewCartClick();
    ">
		{{"saveCart.newCart"|cxTranslate}}
	</button>
	<span class="align-self-center ml-3">
		{{"saveCart.cartsAreAutoSaved"|cxTranslate}}
	</span>
</div>
<div class="p-3">
	<div class="custom-pagination-position">
		<p-paginator
			styleClass="cstm-pagination"
			[first]="first"
			[rows]="rows"
			[totalRecords]="totalRecords"
			(onPageChange)="onPageChange($event)"
		>
		</p-paginator>
	</div>
	<app-table-template
		[columns]="columns"
		[allowPagination]="false"
		[tableData]="(savedCartsListDuplicate | async)"
		[allowQuotation]="true"
		[isRadioButton]="true"
		[apiFilterCall]="true"
		(sortClickEvent)="sortSavedCartsList($event)"
		(customFilter)="filterSavedCartsList($event)"
		(selectCartChange)="onCartChange($event)"
		(duplicateBtnClickEvent)="
			this.buttonName = 'Duplicate';
			saveCartForm.get('cartName').reset();
			duplicateCart($event)
		"
		(deleteBtnClickEvent)="openDeleteModal($event)"
	>
	</app-table-template>
	<div class="custom-pagination-position mt-2">
		<p-paginator
			styleClass="cstm-pagination"
			[first]="first"
			[rows]="rows"
			[totalRecords]="totalRecords"
			(onPageChange)="onPageChange($event)"
		>
		</p-paginator>
	</div>
</div>

<div #cartModal class="modal fade" tabindex="-1" id="cartModal" aria-labelledby="cartModal" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header d-flex justify-content-between align-items-center">
				<h4 class="m-0">
					{{(buttonName == "Create" ? "saveCart.newCart" : "saveCart.duplicatecart")|cxTranslate}}
				</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{"saveCart.youAreCreatingNewCart"|cxTranslate}}</p>
				<form [formGroup]="saveCartForm" class="col-lg-12 p-0">
					<div class="form-row">
						<div class="form-group col-lg-12 p-0">
							<label class="request-label required" for="cartName">
								{{"saveCart.cartName"|cxTranslate}}
							</label>
							<input type="text" class="form-control request-data" formControlName="cartName"
								placeholder="{{ 'saveCart.enterCartName' | cxTranslate }}">
						</div>
						<div class="form-group col-lg-12 p-0">
							<label class="request-label required" for="legalEntity">
								{{"saveCart.legalEntities"|cxTranslate}}
							</label>
							<select formControlName="legalEntity" class="form-control request-data"
								[ngStyle]="{'border-color': (legalEntity?.invalid && legalEntity?.touched) ? 'red' : '#ced4da'}">
								<option *ngFor="let entity of legalEntityList" [value]="entity">
									{{ entity }}
								</option>
							</select>
						</div>
					</div>
				</form>
			</div>
			<div class="modal-footer d-flex justify-content-start mb-2">
				<button type="button" class="btn cstm-btn-light mr-3" data-dismiss="modal">
					{{"saveCart.cancel"|cxTranslate}}
				</button>
				<button type="button" class="btn cstm-btn-primary" (click)="createandDuplicateCart()">
					{{(buttonName == "Create" ? "saveCart.newCart" : "duplicate")|cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>

<div #deleteModal class="modal fade" tabindex="-1" id="deleteModal" aria-labelledby="deleteModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header d-flex justify-content-between align-items-center">
				<h4 class="m-0">{{"saveCart.deletecart" | cxTranslate}}</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>{{"saveCart.confrimDelete" | cxTranslate}}</p>
			</div>
			<div class="modal-footer d-flex justify-content-start mb-2">
				<button type="button" class="btn cstm-btn-primary mr-3" (click)="deleteCart()">
					{{"saveCart.confirm" | cxTranslate}}
				</button>
				<button type="button" class="btn cstm-btn-light" data-dismiss="modal">
					{{"saveCart.cancel" | cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>