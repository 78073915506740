import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  Column,
  ColumnType,
} from '../../../../SGRE-shared/models/tableConfiguration';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { SavedCartsService } from '../../services/saved-carts.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, tap, takeUntil, Subject } from 'rxjs';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { Router } from '@angular/router';

declare let bootstrap: any;
@Component({
  selector: 'app-save-cart',
  templateUrl: './save-cart.component.html',
  styleUrl: './save-cart.component.scss',
})
export class SaveCartComponent implements OnInit, OnDestroy {

  @ViewChild('cartModal') cartModal: ElementRef;
  @ViewChild('deleteModal') deleteModal: ElementRef;

  public columnType: any[];
  public columns: Column[];
  public savedCartsListDuplicate: Observable<any>;
  public savedCartsList: any[];
  public legalEntityList: any[];
  public legalEntityListUid: any[];
  public modalpopup = false;
  public currentPage: number = 0;
  public totalPages: number;
  public pageSize: number;
  public index: number;
  public buttonName = 'Create';
  public saveCartForm: FormGroup;
  public modalInstance: any;
  public errorMessage = null;
  public deleteCartObj: any;
  public first = 0;
  public rows = 20;
  public totalRecords;
  private unsubscribe$ = new Subject<void>();
  public changedIndex: number;
  public legalEntityFilterList;
  public sortObject: any;
  public previousSortField: string | null = null;
  public previousSortOrder: string | null = null;

  get cartName() { return this.saveCartForm.get('cartName'); }
  get legalEntity() { return this.saveCartForm.get('legalEntity'); }

  constructor(
    private savedCartsService: SavedCartsService,
    private globalService: GlobalService,
    private storageService: StorageService,
    private router: Router
  ) {
    this.saveCartForm = new FormGroup({
      cartName: new FormControl('', [Validators.required]),
      legalEntity: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.updateLegalEntities();
    this.getManageCartsDetails();
    this.handleSubscriptions();
  }

  updateLegalEntities() {
    let legalEntities = this.storageService.userLegalEntities;
    this.legalEntityList = legalEntities?.map((item) => item.name);
    this.legalEntityListUid = legalEntities?.map((item) => item.uid);
    this.changedIndex = this.legalEntityList?.findIndex(
      (entity) => entity === this.storageService.defaultLegalEntity?.name
    );
  }

  getManageCartsDetails(obj = {}) {
    this.savedCartsService.getSaveCartFilterList()
      .subscribe((data) => {
        this.legalEntityFilterList = data?.legalEntities?.map((option) => ({
          legalEntity: option.name, uid: option.uid, selected: this.globalService.saveCartsFilter?.includes(option.uid)
        }));
        this.createTableConfiguration();
        this.globalService.getSavedCartsList(obj);
      })
  }

  handleSubscriptions() {
    // Get API for Manage Carts Page
    this.savedCartsListDuplicate = this.globalService.manageCartsList$.pipe(
      tap((data) => {
        this.savedCartsList = data;
      })
    );

    // Pagination
    this.globalService.manageCartsPaginationList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.totalRecords = data;
      });

    // Reset Pagination
    this.globalService.manageCartsPaginationReset$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data) {
          this.first = 0;
        }
      })

    // On Mini Cart Change
    this.globalService.activeCart$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success) => {
          if (success && this.router.url.includes(AppConstants.routeUrls.manageCarts)) {
            this.globalService.updateLegalEntity(this.changedIndex);
          }
        },
      })
  }

  filterSavedCartsList(data) {
    data['legalEntity'] = this.mapUid(data?.legalEntity);
    this.globalService.saveCartsFilter = data?.legalEntity;
    let filterObject = {
      legalEntities: this.globalService.saveCartsFilter?.join(','),
      currentPage: 0,
      pageSize: this.rows,
      fields: 'FULL',
    }
    if (this.globalService.manageCartsSortFlag) {
      filterObject['sort'] = this.sortObject;
    }
    this.first = 0;
    this.getManageCartsDetails(filterObject);
  }

  mapUid(data: []) {
    const legalEntitiesUid = [];
    data?.forEach(item => legalEntitiesUid.push(this.legalEntityListUid[this.legalEntityList.indexOf(item)]));
    return legalEntitiesUid;
  }

  public sortSavedCartsList(event) {
    this.globalService.manageCartsSortFlag = true;
    let field = event?.field;
    if (field === 'cartName') {
      field = 'name';
    } else if (field === 'items') {
      field = 'totalItems';
    } else {
      field = 'totalPrice';
    }
    if (this.previousSortField !== field || this.previousSortOrder !== event?.order) {
      this.previousSortField = field;
      this.previousSortOrder = event?.order;
      this.sortObject = [`${field}` + ":" + `${event.order}`];
      this.globalService.saveCartsSort = this.sortObject;
      let requestObject = {
        legalEntities: this.globalService.saveCartsFilter?.join(','),
        currentPage: this.currentPage,
        pageSize: this.rows,
        fields: 'FULL',
        sort: this.sortObject
      }
      this.getManageCartsDetails(requestObject);
    }
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    this.currentPage = event.page;
    this.globalService.saveCartsCurrentPage = this.currentPage;
    const requestObject = {
      legalEntities: this.globalService.saveCartsFilter?.join(','),
      currentPage: event.page,
      pageSize: this.rows,
      fields: 'FULL',
    };
    if (this.globalService.manageCartsSortFlag) {
      requestObject['sort'] = this.sortObject;
    }
    this.globalService.loadingSubject.next(true);
    this.getManageCartsDetails(requestObject);
  }

  public onNewCartClick() {
    this.saveCartForm.get('cartName').reset();
    this.saveCartForm
      .get('legalEntity')
      ?.setValue(this.storageService?.defaultLegalEntity?.name);
    this.modalInstance = new bootstrap.Modal(this.cartModal.nativeElement);
    this.modalInstance.show();
  }

  onCartChange(cartData: any) {
    const { cart, index } = cartData;
    const selectedLegalEntity = cart.legalEntity;
    if (selectedLegalEntity) {
      this.changedIndex = this.legalEntityList?.findIndex(
        (entity) => entity === selectedLegalEntity
      );
      this.globalService.loadingSubject.next(true);
      let filterObject = {
        legalEntities: this.globalService.saveCartsFilter?.join(','),
        currentPage: this.currentPage,
        pageSize: this.rows,
        fields: 'FULL',
      }
      if (this.globalService.manageCartsSortFlag) {
        filterObject['sort'] = this.sortObject;
      }
      this.globalService.setActiveCart(
        this.storageService.carts,
        this.savedCartsList.at(index),
        {isSaveCart : true, filterObject : filterObject}
      );
    }
  }

  public duplicateCart(index) {
    this.saveCartForm.get('cartName').reset();
    this.saveCartForm
      .get('legalEntity')
      ?.setValue(this.storageService?.defaultLegalEntity?.name);
    this.modalInstance = new bootstrap.Modal(this.cartModal.nativeElement);
    this.modalInstance.show();
    this.index = index;
  }

  public createandDuplicateCart() {
    if (this.saveCartForm.invalid) {
      for (const control of Object.keys(this.saveCartForm.controls)) {
        this.saveCartForm.controls[control].markAsTouched();
      }
      return;
    }
    const index = this.legalEntityList.indexOf(
      this.saveCartForm.value['legalEntity']
    );
    const requestObject = {
      name: this.saveCartForm.value['cartName'],
      cartName: this.saveCartForm.value['cartName'],
      legalEntityId: this.legalEntityListUid[index],
      fields: 'FULL',
    };
    // pagination / sort / filter if any - will be applied
    this.globalService.manageCartsSortFlag = false;

    if (this.buttonName === 'Create') {
      this.savedCartsService.createCart(requestObject)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (success) => {
            if (success && this.modalInstance) {
              this.modalInstance.hide();
              this.globalService.updateLegalEntity(index);
            }
          },
          error: (error) => {
            if (this.modalInstance) this.modalInstance.hide();
            this.errorMessage = error.error.message;
          },
        })
    } else {
      let cartId = this.savedCartsList[this.index].code;
      this.savedCartsService.duplicateCart(cartId, requestObject)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (success) => {
            if (this.modalInstance) this.modalInstance.hide();
            this.globalService.updateLegalEntity(index);
          },
          error: (error) => {
            if (this.modalInstance) this.modalInstance.hide();
            this.errorMessage = error.error.message;
          },
        })
    }
  }

  public deleteCart() {
    this.savedCartsService.deleteCart(this.deleteCartObj.code)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success) => {
          if (this.modalInstance) this.modalInstance.hide();
          // pagination / sort / filter if any - will be applied
          this.globalService.manageCartsSortFlag = false;
          this.globalService.getCartsListByLegalEntity(undefined, true);
          if (this.deleteCartObj.selected) {
            const legalEntity = this.storageService.userLegalEntities.filter(
              (ule) => ule.name === this.deleteCartObj.legalEntity
            );
            let leUID = legalEntity.at(0).uid;
            const requestObject = {
              name: 'Default Cart',
              cartName: 'Default Cart',
              legalEntityId: leUID,
              fields: 'FULL',
            };
            this.savedCartsService.createCart(requestObject)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe({
                next: (success) => {
                  if (this.modalInstance) this.modalInstance.hide();
                  this.globalService.getCartsListByLegalEntity(undefined, true);
                },
                error: (error) => {
                  if (this.modalInstance) this.modalInstance.hide();
                  this.errorMessage = error.error.message;
                },
              });
          }
        },
        error: (error) => {
          if (this.modalInstance) this.modalInstance.hide();
          this.errorMessage = error.error.message;
        },
      });
  }

  public openDeleteModal(rowIndex) {
    this.modalInstance = new bootstrap.Modal(this.deleteModal.nativeElement);
    this.modalInstance.show();
    this.deleteCartObj = this.savedCartsList.at(rowIndex);
  }

  createTableConfiguration() {
    let columnData = [
      {
        label: 'Cart Name',
        name: 'cartName',
        type: ColumnType.text,
        sort: true,
        style: 'large',
      },
      {
        label: 'Legal Entity',
        name: 'legalEntity',
        type: ColumnType.text,
        filter: true,
        filterOptions: this.legalEntityFilterList,
        style: 'large',
      },
      {
        label: 'Items',
        name: 'items',
        type: ColumnType.number,
        sort: true,
      },
      {
        label: 'Price',
        name: 'price',
        type: ColumnType.price,
        sort: true,
      },
      {
        label: 'Duplicate Cart',
        name: 'duplicate',
        type: ColumnType.duplicate,
      },
      {
        label: 'Delete Cart',
        name: 'delete',
        type: ColumnType.delete,
      },
      {
        label: 'Select Cart',
        name: 'select',
        type: ColumnType.radio,
      },
    ];
    this.columns = columnData.map((item) => new Column(item));
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.manageCartsSortFlag = false;
    this.globalService.saveCartsCurrentPage = 0;
    this.globalService.saveCartsSort = '';
    this.globalService.saveCartsFilter = [];
    this.globalService.clearMessagesOnDestroy();
  }
}
