export enum ColumnType {
  text,
  link,
  days,
  date,
  tick,
  tick2,
  radio,
  image,
  price,
  delete,
  number,
  button,
  navigate,
  quantity,
  checkbox,
  duplicate,
  removeAndReview,
  info,
  totalprice,
  checkbox2,
  link2,
  blank,
  comment,
  image2,
  eta
}

export class Column {
  label: string;
  name: string;
  type: ColumnType;
  sort?: boolean = false;
  filter?: boolean = false;
  filterPlaceHolder?: string;
  filterOptions?: any[] = [];
  style?: string = 'medium';
  align?: string = 'left';
  validations?: any[] = [];
  orgEta?: boolean = false;

  constructor(data) {
    this.label = data.label;
    this.name = data.name;
    this.type = data.type;
    this.sort = data?.sort ? data.sort : false;
    this.filter = data?.filter ? data.filter : false;
    this.filterOptions = data?.filterOptions ? data.filterOptions : [];
    this.style = data?.style ? data.style : 'medium';
    this.align = data?.align ? data.align : 'left';
    this.validations = data?.validations ? data.validations : [];
    this.filterPlaceHolder = 'Filter by ' + data.label;
    this.orgEta = data?.orgEta ? data?.orgEta : false;
  }
}
